import dragonLower from '../img/dragonbanner_web_bottom.png'

const HeaderLower = () => {

    return (
        <div className="headerLower">
            <img src={dragonLower} alt="Dragon Lower" />
        </div>
    )
}

export default HeaderLower;